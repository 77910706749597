
import { img_team } from './images';

export const teams = {
    organization: [
        { name: "Alice Johnson", role: "Professor", image: img_team.teamExample, link: "https://www.google.com"  },
        { name: "Bob Smith", role: "Professor", image: img_team.teamExample, link: "https://www.google.com"  },
        { name: "Bob Smith", role: "Professor", image: img_team.teamExample, link: "https://www.google.com"  },
        { name: "Bob Smith", role: "Professor", image: img_team.teamExample, link: "https://www.google.com"  },
    ],
    logistic: [
        { name: "Carol White", role: "Postdoc", image: img_team.teamExample, link: "https://www.google.com"  },
        { name: "Dave Brown", role: "Postdoc", image: img_team.teamExample, link: "https://www.google.com"  },
        { name: "Dave Brown", role: "Postdoc", image: img_team.teamExample, link: "https://www.google.com"  },
        { name: "Dave Brown", role: "Postdoc", image: img_team.teamExample, link: "https://www.google.com"  },
    ],
    communication: [
        { name: "Eve Black", role: "PhD Student", image: img_team.teamExample, link: "https://www.google.com" },
        { name: "Frank Green", role: "PhD Student", image: img_team.teamExample, link: "https://www.google.com"  },
        { name: "Frank Green", role: "PhD Student", image: img_team.teamExample, link: "https://www.google.com"  },
        { name: "Frank Green", role: "PhD Student", image: img_team.teamExample, link: "https://www.google.com"  },
    ]
};