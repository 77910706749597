import React, { useRef, useState } from 'react';
import './CardSlider.css';

const CardSlider = ({ places }) => {
    const sliderRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust the speed
        sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleTouchStart = (e) => {
        setIsDragging(true);
        setStartX(e.touches[0].pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const x = e.touches[0].pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust the speed
        sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    const handleDragStart = (e) => {
        e.preventDefault();
    };

    return (
        <div
            className="card-slider"
            ref={sliderRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {places.map((place, index) => (
                <div key={index} className="card">
                    <img 
                        src={place.src} 
                        alt={place.alt} 
                        className="card-image" 
                        onDragStart={handleDragStart} 
                    />
                    <div className="card-info">
                        <h3>{place.name}</h3>
                        <p>{place.location}</p>
                        <p>{place.price}</p>
                        <a href={place.link} target="_blank" rel="noopener noreferrer">More info</a>
                        <a href={place.location_link} target="_blank" rel="noopener noreferrer">Location</a>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CardSlider;
