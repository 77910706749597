import React from 'react';
import './Home.css';
import Timeline from "../../assets/images/Timeline.png";
// import Landing from "../../assets/images/landing-library.png";

const Home = () => {
    return (
        <div className="App">
            <div className="App-Home">
                <div className="landing-picture-container ContType1">
                    <div className="landing-picture">
                        <div className="HomeTitleCont">
                            <div className="landing-text-container">
                                <div className="landing-details">
                                    <p className="landing-date">MAY 26-28 | LEUVEN</p>
                                    <p className="landing-title">Preparing for the Web of Tomorrow</p>
                                    <p className="landing-main-title">ACCELERATE GROWTH WITH SEO, CONTENT, & TECHNICAL OPTIMIZATION</p>
                                    <p className="landing-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">                    
                    {/* <div className="ContType4"> */}
                        <div className= "twocols-container">
                            <div className= "regular">
                                <h2 className="regular-title">What is SAA 2025?</h2>
                                <p className="regular-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference. Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference. </p>
                                <p className="regular-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference. Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference. </p>
                            </div>
                            <div className="twocols-image image-timeline">
                                <img src={Timeline} alt="important dates" />
                                {/* <div className="twocols-post">Professor at the University of xxx</div> */}
                            </div>
                        </div>
                    {/* </div> */}
                </div>
                <div className="ContType3 HomeCont">
                    <div className= "regular">
                        <h2 className="regular-title">What is SAA 2025?</h2>
                        <p className="regular-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference. Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference. </p>
                        <p className="regular-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference. Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference. </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;