// src/constants/places.js
import { img_leuven, img_hotel, img_eat, img_drink} from './images';

export const places = [
    { name: "City hall", location: "Center", alt: "xxx", src: img_leuven.leuvenExample, link: "https://www.google.com" },
    { name: "City hall", location: "Center", alt: "xxx", src: img_leuven.leuvenExample, link: "https://www.google.com" },
    { name: "City hall", location: "Center", alt: "xxx", src: img_leuven.leuvenExample, link: "https://www.google.com" },
    { name: "City hall", location: "Center", alt: "xxx", src: img_leuven.leuvenExample, link: "https://www.google.com" },
    { name: "City hall", location: "Center", alt: "xxx", src: img_leuven.leuvenExample, link: "https://www.google.com" },
    { name: "City hall", location: "Center", alt: "xxx", src: img_leuven.leuvenExample, link: "https://www.google.com" },
    { name: "City hall", location: "Center", alt: "xxx", src: img_leuven.leuvenExample, link: "https://www.google.com" },
    { name: "City hall", location: "Center", alt: "xxx", src: img_leuven.leuvenExample, link: "https://www.google.com" },
];

export const places_eat = [
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_eat.eatExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_eat.eatExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_eat.eatExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_eat.eatExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_eat.eatExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_eat.eatExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 2, name: "Nosh", location: "Center", alt: "xxx", src: img_eat.eatExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€€" },
    { price_cat: 3, name: "Fancy", location: "Center", alt: "xxx", src: img_eat.eatExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€€€" },
];

export const places_drink = [
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_drink.drinkExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_drink.drinkExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_drink.drinkExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_drink.drinkExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_drink.drinkExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 1, name: "Fastfood", location: "Center", alt: "xxx", src: img_drink.drinkExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€" },
    { price_cat: 2, name: "Nosh", location: "Center", alt: "xxx", src: img_drink.drinkExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€€" },
    { price_cat: 3, name: "Fancy", location: "Center", alt: "xxx", src: img_drink.drinkExample, link: "https://www.google.com", location_link: "https://www.google.com/maps", price: "€€€" },
];


// BE CARFUL TO HAVE ONLY ONE ID PER ELEMENT
export const accommodations = [
    { id: 1, name: "Hotel A", price: 80, category:"50-100", distanceFoot: "10 mins", distancePublic: "5 mins", info: "Includes breakfast", src: img_hotel.hotelExample, link: "https://www.google.com" },
    { id: 2, name: "Hotel B", price: 150, category:"100-200", distanceFoot: "15 mins", distancePublic: "7 mins", info: "Pool and gym access", src: img_hotel.hotelExample, link: "https://www.google.com" },
    { id: 3, name: "Hotel B", price: 150, category:"100-200", distanceFoot: "15 mins", distancePublic: "7 mins", info: "Pool and gym access", src: img_hotel.hotelExample, link: "https://www.google.com" },
    { id: 4, name: "Hotel C", price: 250, category:"200-300", distanceFoot: "5 mins", distancePublic: "2 mins", info: "Luxury suite", src: img_hotel.hotelExample, link: "https://www.google.com" },
    // Add more accommodations as needed
];
