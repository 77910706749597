import React from 'react';
import { useParams } from 'react-router-dom';
import { programData } from '../../constants/programData'; // Adjust the import path
import './Symposium.css';

const getSymposium = (ref) => {
    for (let date in programData) {
        for (let session of programData[date]) {
            for (let event of session.events) {
                if (event.ref === ref && event.type === "symposium") {
                    return event;
                }
            }
        }
    }
    return null;
};

const Symposium = () => {
    const { ref } = useParams();
    const symposium = getSymposium(ref);

    if (!symposium) {
        return <div>Symposium not found</div>;
    }

    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Symposium {symposium.number} | SAA 2025
                    </div>
                </div>
                <div className="ContType4 HomeCont">
                    <div className="symp-section">
                        <div className="regular">
                            <h2 className="regular-title">{symposium.title}</h2>
                            <div className='symp-details'>
                                <p className='symp-chair'>Chair: {symposium.name}</p>
                                <p className='symp-time'>Time: {symposium.time}</p>
                                <p className='symp-room'>{symposium.location}</p>
                            </div>
                            <p className="regular-description">{symposium.abstract}</p>
                        </div>
                    </div>
                    {symposium.presentations.map((presentation, index) => (
                        <div className="symp-section" key={index}>
                            <div className="regular">
                                <h2 className="sym-topic-title">{presentation.title}</h2>
                                <div className='symp-details'>
                                    <p className='symp-chair'><span className='topic-main-author'>{presentation.presenter}</span>, {presentation.authors}</p>
                                </div>
                                <p className="regular-description">{presentation.abstract}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Symposium;
