import React from 'react';
import './Register.css';

const Register = () => {
    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Register | SAA 2025
                    </div>
                    <div className="registerButton register-page-button">
                        <button>Register Now</button>
                    </div>
                </div>
                <div className="ContType4 HomeCont">
                    <div className="registration-info">
                        <h2>REGISTRATION FEES</h2>
                        <p>All fees are in US Dollars. Your registration fee includes: attendance at all sessions, conference materials, receptions, and banquets.</p>

                        <h3>Early Registration Fee (registration completed and paid by April 15th)</h3>
                        <ul>
                            <li>Early Career Member - $175</li>
                            <li>Full/Emeritus Member - $325</li>
                            <li>Non-member - $450</li>
                            <li>Student/Trainee Non-member- $175</li>
                        </ul>
                        <p>Student and trainee non-member includes undergraduates, graduate students, postbaccalaureate research assistants, and postdoctoral fellows.</p>

                        <h3>Registration Fee (registration completed and paid after April 15th)</h3>
                        <ul>
                            <li>Early Career Member - $225</li>
                            <li>Full/Emeritus Member - $375</li>
                            <li>Non-member - $500</li>
                            <li>Student/Trainee Non-member- $225</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
