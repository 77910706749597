// LEUVEN CITY list
import leuvenExample from '../assets/images/leuven-example.jpg';

// HOTEL list
import hotelExample from '../assets/images/hotel_example.jpg';


// EAT list
import eatExample from '../assets/images/eat_example.jpg';


// DRINK list
import drinkExample from '../assets/images/drink_example.jpg';


// KEYNOTE list
import keynoteExample from '../assets/images/speaker_example.jpeg';

// TEAM list
import teamExample from '../assets/images/speaker_example.jpeg';

// WORKSHOP list
import workshopExample from '../assets/images/workshop_example.png';



export const img_leuven = {
    leuvenExample: leuvenExample,
};


export const img_hotel = {
    hotelExample: hotelExample,
}

export const img_eat = {
    eatExample: eatExample,
}

export const img_drink = {
    drinkExample: drinkExample,
}

export const img_keynote = {
    keynoteExample: keynoteExample,
}

export const img_team = {
    teamExample: teamExample,
}

export const img_workshop = {
    workshopExample: workshopExample,
}


