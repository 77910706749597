import React from 'react';
import { useParams } from 'react-router-dom';
import { programData } from '../../constants/programData'; // Adjust the import path
import './Workshop.css';

const getWorkshop = (ref) => {
    for (let date in programData) {
        for (let session of programData[date]) {
            for (let event of session.events) {
                if (event.ref === ref && event.type === "workshop") {
                    return event;
                }
            }
        }
    }
    return null;
};

const Workshop = () => {
    const { ref } = useParams();
    const workshop = getWorkshop(ref);

    if (!workshop) {
        return <div>Workshop not found</div>;
    }

    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Workshop {workshop.number} | SAA 2025
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className="twocols-container">
                        <div className="regular">
                            <h2 className="twocols-title">{workshop.title}</h2>
                            <p className="regular-description">{workshop.abstract}</p>
                        </div>
                        <div className="twocols-image workshop-picture">
                            <img src={workshop.picture} alt={workshop.name} />
                        </div>
                    </div>
                </div>
                <div className="ContType4">
                    <div className="card-big">
                        <div className="regular">
                            <h2 className="regular-title">More about the presenters</h2>
                            <p className="regular-description">{workshop.bio}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Workshop;
