
import speaker1 from "../assets/images/speaker_example.jpeg";
import workshop_example from "../assets/images/workshop_example.png";

export const programData = {
    "05 June 2025": [
        {
            time: "09:00 - 10:00",
            events: [
                {
                    ref: null,
                    title: "Check in",
                    location: "Room 1",
                    type: "organization",
                }
            ]
        },
        {
            time: "09:00 - 10:00",
            events: [
                {
                    ref: "Keynote1",
                    title: "Emotion captured by ESM: revolutionary and future development which are incredible",
                    abstract: "Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing in the industry at Conductor’s annual website optimization and digital marketing conference. Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing in the industry at Conductor’s annual website optimization and digital marketing conference.",
                    bio: "Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing in the industry at Conductor’s annual website optimization and digital marketing conference. Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing in the industry at Conductor’s annual website optimization and digital marketing conference.",
                    location: "Room 1",
                    type: "keynote",
                    name: "Gabriela Rogrigua Lavalato",
                    gender: "her",
                    affiliation: "University of Penn",
                    picture: speaker1,
                }
            ]
        },
        {
            time: "09:00 - 10:00",
            events: [
                {
                    ref: "Workshop1",
                    number: 1,
                    title: "Data science for mental health",
                    abstract: "In this workshop, participants will delve into the transformative potential of data science in the realm of mental health. The session will explore how big data, machine learning, and advanced analytics can be leveraged to understand and address complex mental health issues. Attendees will learn about the latest research and technological advancements that enable the prediction, prevention, and treatment of mental health disorders through data-driven approaches. This workshop aims to equip mental health professionals, researchers, and data scientists with the necessary skills to harness data science tools for enhancing mental health care and outcomes. By the end of the session, participants will have a better understanding of how to integrate data science into their practice to foster a more informed, effective, and personalized approach to mental health treatment.",
                    shortabstract: "This presentation will cover...",
                    bio: "Dr. Emily Johnson is a leading expert in the field of data science with a specific focus on its applications in mental health. She holds a Ph.D. in Clinical Psychology and a Master’s degree in Data Science. Dr. Johnson has over a decade of experience in mental health research, where she has successfully integrated data-driven methods to advance the understanding and treatment of various mental health conditions. Currently, Dr. Johnson is a Professor of Mental Health Data Science at the University of Technology, where she leads a multidisciplinary research team. Her work involves developing and applying machine learning algorithms to predict mental health crises and improve intervention strategies. She has published extensively in top-tier journals and is a sought-after speaker at international conferences on both data science and mental health.",
                    location: "Room 1",
                    type: "workshop",
                    name: "Gabriela Rogrigua Lavalato",
                    gender: "her",
                    affiliation: "University of Penn",
                    picture: workshop_example,
                }
            ]
        },
        {
            time: "10:00 - 11:00",
            events: [
                {
                    ref: "Symp1",
                    number: 1,
                    title: "Using experiencing sampling data to forecast, prevent and treat mental health problems in real-time: from complex systems to just-in-time adaptive interventions",
                    location: "Room 1",
                    time: "10:00 - 11:00",
                    type: "symposium",
                    name: "Gabriela Rogrigua Lavalato",
                    abstract: "Within-person changes in emotion dynamics, such as rising variances andautocorrelations, were recently put forward as person-specific warning signs forupcoming mental disorders. Yet, empirical support for this idea remained limited.The first part of this symposium will therefore broaden the scope regarding the typeof warning signs that may forewarn psychological transitions. First, Kyra Evers willpresent how recurrence quantification analysis (RQA) compares to moreconventional warning signs for anticipating a range of simulated psychologicaltransitions. Second, Evelien Schat will present how monitoring mean levels throughstatistical process control (SPC) may aid the early detection of mental healthproblems. In particular, it will be addressed whether the “normative” range of meanlevels can be derived from group-level experience sampling data. Marieke Schreuderalso addressed this in an empirical study that aimed to distinguish youth with vs.without persistent mental health problems by means of SPC. Evidently, the ultimategoal of monitoring warning signs – whether detected through RQA or SPC – is toprevent or treat mental health in real-time. Therefore, the second part of thissymposium features two presentations that illustrate the promises and boundariesof just-in-time adaptive interventions (JITAIs). Arnout Smit will discuss theoreticalframeworks for JITAIs in order to outline present and future of JITAIs in mentalhealth research. Finally, Eeske van Roekel will present a study on the feasibility ofJITAIs in first-year university students. Altogether, this symposium features some ofthe latest findings with respect to anticipating, preventing and treating mental healthproblems in real-time.",
                    presentations: [
                        {
                            type: "presentation",
                            title: "Anticipating Regime Changes: A Comparison Between Conventional Early Warning Signals and Recurrence Quantification Analysis in the Generalised Lotka-Volterra Model",
                            presenter: "Kyra Evers, ",
                            authors: "John Doe, Jane Smith",
                            abstract: "Within-person changes in emotion dynamics, such as rising variances andautocorrelations, were recently put forward as person-specific warning signs forupcoming mental disorders. Yet, empirical support for this idea remained limited.The first part of this symposium will therefore broaden the scope regarding the typeof warning signs that may forewarn psychological transitions. First, Kyra Evers willpresent how recurrence quantification analysis (RQA) compares to moreconventional warning signs for anticipating a range of simulated psychologicaltransitions. Second, Evelien Schat will present how monitoring mean levels throughstatistical process control (SPC) may aid the early detection of mental healthproblems. In particular, it will be addressed whether the “normative” range of meanlevels can be derived from group-level experience sampling data. Marieke Schreuderalso addressed this in an empirical study that aimed to distinguish youth with vs.without persistent mental health problems by means of SPC. Evidently, the ultimategoal of monitoring warning signs – whether detected through RQA or SPC – is toprevent or treat mental health in real-time. Therefore, the second part of thissymposium features two presentations that illustrate the promises and boundariesof just-in-time adaptive interventions (JITAIs). Arnout Smit will discuss theoreticalframeworks for JITAIs in order to outline present and future of JITAIs in mentalhealth research. Finally, Eeske van Roekel will present a study on the feasibility ofJITAIs in first-year university students. Altogether, this symposium features some ofthe latest findings with respect to anticipating, preventing and treating mental healthproblems in real-time."
                        },
                        {
                            type: "presentation",
                            title: "Can warning signals for mental health problems in at-risk young adults be informed by momentary emotions reported by the general population? A novel application of the principles of statistical process control",
                            presenter: "Evelien Schat, ",
                            authors: "Alice Johnson, Bob Brown",
                            abstract: "Within-person changes in emotion dynamics, such as rising variances andautocorrelations, were recently put forward as person-specific warning signs forupcoming mental disorders. Yet, empirical support for this idea remained limited.The first part of this symposium will therefore broaden the scope regarding the typeof warning signs that may forewarn psychological transitions. First, Kyra Evers willpresent how recurrence quantification analysis (RQA) compares to moreconventional warning signs for anticipating a range of simulated psychologicaltransitions. Second, Evelien Schat will present how monitoring mean levels throughstatistical process control (SPC) may aid the early detection of mental healthproblems. In particular, it will be addressed whether the “normative” range of meanlevels can be derived from group-level experience sampling data. Marieke Schreuderalso addressed this in an empirical study that aimed to distinguish youth with vs.without persistent mental health problems by means of SPC. Evidently, the ultimategoal of monitoring warning signs – whether detected through RQA or SPC – is toprevent or treat mental health in real-time. Therefore, the second part of thissymposium features two presentations that illustrate the promises and boundariesof just-in-time adaptive interventions (JITAIs). Arnout Smit will discuss theoreticalframeworks for JITAIs in order to outline present and future of JITAIs in mentalhealth research. Finally, Eeske van Roekel will present a study on the feasibility ofJITAIs in first-year university students. Altogether, this symposium features some ofthe latest findings with respect to anticipating, preventing and treating mental healthproblems in real-time."
                        },
                    ],
                },
                {
                    ref: "Symp2",
                    number: 2,
                    title: "Feedback and prediction of mental health: focusing on the individual",
                    location: "Room 1",
                    type: "symposium",
                    name: "Gabriela Rogrigua Lavalato",
                    abstract: "This presentation will cover...",
                    presentations: [
                        {
                            type: "presentation",
                            title: "Presentation Title 1",
                            presenter: "Kyra Evers",
                            authors: "Kyra Evers, John Doe, Jane Smith",
                            abstract: "This presentation will cover..."
                        },
                        {
                            type: "presentation",
                            title: "Presentation Title 2",
                            presenter: "Evelien Schat",
                            authors: "Evelien Schat, Alice Johnson, Bob Brown",
                            abstract: "This presentation will discuss..."
                        },
                    ],
                }
            ]
        }
    ]
};
