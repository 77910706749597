import React from 'react';
import { Link } from 'react-router-dom';
import ProgramTable from '../ProgramTable';
import './Program.css';
import speaker1 from "../../assets/images/speaker_example.jpeg";
// import workshop from "../../assets/images/workshop_example.png";
import { programData } from '../../constants/programData';
import { img_keynote, img_workshop } from '../../constants/images';


const Program = () => {
    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Program | SAA 2025
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className= "regular">
                        <h2 className="regular-title">Keynote speakers</h2>
                        <div className="card-container">
                            <div className="card">
                                <Link to="/keynote/Keynote1">
                                    <img src={img_keynote.keynoteExample} alt="speaker1" />
                                    <div className="card-title">Keynote 1</div>
                                    <div className="card-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference.</div>
                                </Link>
                            </div>
                            <div className="card">
                                <Link to="/keynote/Keynote1">
                                    <img src={speaker1} alt="speaker1" />
                                    <div className="card-title">Keynote 2</div>
                                    <div className="card-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference.</div>
                                </Link>
                            </div>
                            <div className="card">
                                <Link to="/keynote/Keynote1">
                                    <img src={speaker1} alt="speaker1" />
                                    <div className="card-title">Keynote 3</div>
                                    <div className="card-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference.</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ContType3 HomeWideCont">
                    <div className= "regular">
                        <h2 className="regular-title">Workshops</h2>
                        <div className="card-container">
                            <div className="card cardWhiteStyle">
                                <Link to="/workshop/Workshop1">
                                    <img src={img_workshop.workshopExample} alt="workshop1" />
                                    <div className="card-title">Workshop 1</div>
                                    <div className="card-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference.</div>
                                </Link>
                            </div>
                            <div className="card cardWhiteStyle">
                                <Link to="/workshop/Workshop1">
                                    <img src={img_workshop.workshopExample} alt="workshop1" />
                                    <div className="card-title">Workshop 2</div>
                                    <div className="card-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference.</div>
                                </Link>
                            </div>
                            <div className="card cardWhiteStyle">
                                <Link to="/workshop/Workshop1">
                                    <img src={img_workshop.workshopExample} alt="workshop1" />
                                    <div className="card-title">Workshop 3</div>
                                    <div className="card-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference.</div>
                                </Link>
                            </div>
                            <div className="card cardWhiteStyle">
                                <Link to="/workshop/Workshop1">
                                    <img src={img_workshop.workshopExample} alt="workshop1" />
                                    <div className="card-title">Workshop 3</div>
                                    <div className="card-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference.</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="ContType2 HomeWideCont">
                    <div className= "regular">
                        <h2 className="regular-title">Program at a glance</h2>
                        <ProgramTable ProgramData={programData} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Program;