import React from 'react';
import { Link } from 'react-router-dom';
import { programData } from '../constants/programData'; // Adjust the import path
import './ProgramTable.css';

const getEventClass = (event) => {
    switch (event.type) {
        case 'organization':
            return 'event-break';
        case 'keynote':
            return 'event-keynote';
        case 'symposium':
            return 'event-symposium';
        default:
            return 'event-session';
    }
};

const renderEvent = (event, idx) => {
    const eventContent = (
        <div className="program-event" key={idx}>
            <div className="event-title">{event.title}</div>
            {event.type === "keynote" && (
                <div className="event-chair">
                    <span className="keynote-word">Keynote:</span> {event.name}
                </div>
            )}
            {event.type === "symposium" && (
                <div className="event-chair">
                    <span className="chair-word">Chair:</span> {event.name}
                </div>
            )}
            {event.type === "workshop" && (
                <div className="event-chair">
                    <span className="keynote-word">Workshop:</span> {event.name}
                </div>
            )}
            <div className="event-location">{event.location}</div>
        </div>
    );

    return event.ref ? (
        <Link to={`/${event.type}/${event.ref}`} key={idx}>
            {eventContent}
        </Link>
    ) : (
        <a role="button" id="desactivelink" tabindex="0" href="www.google.com">
            {eventContent}
        </a>
    );
};

const ProgramTable = () => {
    if (!programData || Object.keys(programData).length === 0) {
        return <div>No program data available.</div>;
    }

    return (
        <div className="program-container">
            {Object.keys(programData).map((date) => (
                <div className="program-oneday" key={date}>
                    <div className="program-date-title">{date}</div>
                    {programData[date].map((session, index) => (
                        <div className="program-cell" key={index}>
                            <div className={`program-time ${getEventClass(session.events[0])}`}>{session.time}</div>
                            <div className="program-events">
                                {session.events.map(renderEvent)}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default ProgramTable;
