import React from 'react';
import './Contact.css';
// import speakerExample from '../../assets/images/speaker_example.jpeg';
import { teams } from '../../constants/team';



const Contact = () => {
    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Contact & Team | SAA 2025
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className= "twocols-container twocols-container-contact">
                        <div className= "regular">
                            <h2 className="twocols-title">Program, Abstract submission, Sponsors</h2>
                            <p className="regular-description p-justify-texts">A platform for intensive longitudinal methods (ESM/EMA), daily life research, mobile sensing and ecological momentary interventions built by researchers for researchers.</p>
                            <div className="contact-info">
                                <p>Ms Natascha Stroo</p>
                                <p>Department of Biological Psychology</p>
                                <p>Human Behavioral and Movement Sciences</p>
                                <br />
                                <p>Vrije Universiteit Amsterdam</p>
                                <br />
                                <p>H-533, van der Boechorststraat 7, 1081 BT Amsterdam</p>
                                <p>Email: <a href="mailto:n.stroo@vu.nl">n.stroo@vu.nl</a></p>
                            </div>
                        </div>
                        {/* Draw a vertical line */}
                        <div className="vertical-line"></div>
                        <div className= "regular">
                            <h2 className="twocols-title">Registration, Venue, Accommodation</h2>
                            <p className="regular-description p-justify-text">A platform for intensive longitudinal methods (ESM/EMA), daily life research, mobile sensing and ecological momentary interventions built by researchers for researchers.</p>
                            <div className="contact-info">
                                <p>Ms Natascha Stroo</p>
                                <p>Department of Biological Psychology</p>
                                <p>Human Behavioral and Movement Sciences</p>
                                <br />
                                <p>Vrije Universiteit Amsterdam</p>
                                <br />
                                <p>H-533, van der Boechorststraat 7, 1081 BT Amsterdam</p>
                                <p>Email: <a href="mailto:n.stroo@vu.nl">n.stroo@vu.nl</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ContType2 HomeCont">
                    <h2 className="regular-title">Teams</h2>
                    <div className="trombinoscope-container">
                        {Object.keys(teams).map(team => (
                            <div key={team} className="team-section">
                                <h2 className="team-title">{team.charAt(0).toUpperCase() + team.slice(1)}</h2>
                                <div className="team-members">
                                    {teams[team].map(member => (
                                        <a key={member.name} href={member.link}>
                                            <div key={member.name} className="team-member">
                                                <img src={member.image} alt={member.name} className="member-image" />
                                                <div className="member-info">
                                                    <h3 className="member-name">{member.name}</h3>
                                                    <p className="member-role">{member.role}</p>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;