import React from 'react';
import { useParams } from 'react-router-dom';
import { programData } from '../../constants/programData'; // Adjust the import path

const getKeynote = (ref) => {
    for (let date in programData) {
        for (let session of programData[date]) {
            for (let event of session.events) {
                if (event.ref === ref && event.type === "keynote") {
                    return event;
                }
            }
        }
    }
    return null;
};

const Keynote = () => {
    const { ref } = useParams();
    const keynote = getKeynote(ref);

    if (!keynote) {
        return <div>Keynote not found</div>;
    }

    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        {keynote.name} | SAA 2025
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className="twocols-container twocols-container-inverse">
                        <div className="regular">
                            <h2 className="twocols-title">{keynote.title}</h2>
                            <p className="regular-description">{keynote.abstract}</p>
                        </div>
                        <div className="twocols-image">
                            <img src={keynote.picture} alt={keynote.name} />
                            <div className="twocols-post">{keynote.affiliation}</div>
                        </div>
                    </div>
                </div>
                <div className="ContType4">
                    <div className="card-big">
                        <div className="regular">
                            <h2 className="regular-title">More about {keynote.gender}</h2>
                            <p className="regular-description">{keynote.bio}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Keynote;
