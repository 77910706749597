import React from 'react';
// import { Link } from 'react-router-dom';
import './ComingSoon.css';

const ComingSoon = () => {
    return (
        <div className="App-ComingSoon">
          <header className="ComingSoon-header">
            <p style={{ fontSize: '50px', fontWeight: 'bold' }}>
              Coming soon
            </p>
          </header>
        </div>    
    );
}

export default ComingSoon;