import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
// import logonav from '../assets/images/logo-navbar.png';
import logowhite from '../assets/images/logo-white.png';
import logosocial from '../assets/images/logo-social.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <img src={logowhite} alt="SAA 2025" className="footer-logo" />
                    <p>Sponsored by</p>
                    <div className="footer-logo-container">
                    <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><img src={logosocial} alt="Sponsor1" className="conductor-logo" /></a>
                    <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><img src={logosocial} alt="Sponsor1" className="conductor-logo" /></a>
                    <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><img src={logosocial} alt="Sponsor1" className="conductor-logo" /></a>
                    <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><img src={logosocial} alt="Sponsor1" className="conductor-logo" /></a>
                    </div>    
                </div>
                <div className="footer-section">
                    <h3>Ressources</h3>
                    <ul>
                        <li><Link to="/Contact">Contact Us</Link></li>
                        {/* Link to SAA website */}
                        <li><a href="https://ambulatory-assessment.org/">SAA Website</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>SAA 2025</h3>
                    <ul>
                        <li><Link to="/Program">Program</Link></li>
                        <li><Link to="/FAQ">FAQ</Link></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>Social media</h3>
                    <div className="social-icons">
                        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter} /></a>
                        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                        {/* <Link to="https://www.youtube.com/"><FontAwesomeIcon icon={faPlane} /></Link> */}
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; SAA 2025. All Rights Reserved</p>
            </div>
        </footer>
    );
};

export default Footer;