import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logonav from '../assets/images/logo-navbar.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navBar">
            <div className="navContainer">
                <div className="logo">
                    <Link to="/">
                        <img src={logonav} alt="logo" />
                        <div className="logo-text">
                            <div className="logo-bar"></div>
                            <div className="logo-description">
                                <p className="line1">SAA 2025</p>
                                <p className="line2">KU Leuven</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={`menuContainer ${isOpen ? 'open' : ''}`}>
                    <div className="menu">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/Program">Program</Link></li>
                            <li><Link to="/Venue">Venue</Link></li>
                            <li><Link to="/Leuven">Visit Leuven</Link></li>
                            <li><Link to="/Sponsors">Sponsors</Link></li>
                            <li><Link to="/Contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="registerButton">
                        <Link to="/Register">
                            <button>Register | Submit</button>
                        </Link>
                    </div>
                </div>
                <div className="burger" onClick={toggleMenu}>
                    <div className={`bar ${isOpen ? 'open' : ''}`}></div>
                    <div className={`bar2 ${isOpen ? 'open' : ''}`}></div>
                    <div className={`bar3 ${isOpen ? 'open' : ''}`}></div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
