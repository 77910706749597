import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faCar, faTrain, faBus, faWalking } from '@fortawesome/free-solid-svg-icons';
import { accommodations } from '../../constants/places';
import './Venue.css';
import map_example from '../../assets/images/map_example.png';

const Venue = () => {
    const [categoryFilter, setCategoryFilter] = useState(null);

    const handleFilterChange = (category) => {
        console.log("Category selected:", category);
        setCategoryFilter(category);
    };

    const filteredAccommodations = categoryFilter
        ? accommodations.filter(acc => acc.category === categoryFilter)
        : accommodations;

    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Venue & Accommodation | SAA 2025
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className="twocols-container twocols-container-inverse">
                        <div className="regular">
                            <h2 className="twocols-title">Emotion captured by ESM: revolutionary and future development which are incredible</h2>
                            <p className="regular-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing in the industry at Conductor’s annual website optimization and digital marketing conference.</p>
                            <p className="regular-description">Join the biggest names and brightest minds in the industry at Conductor’s annual website optimization and digital marketing conference. Join the biggest names and brightest minds optimization and digital marketing conference.</p>
                        </div>
                        <div className="twocols-image">
                            <img src={map_example} alt="map_example" />
                        </div>
                    </div>
                </div>
                <div className="ContType4 HomeCont">
                    <div className="twocols-container">
                        <div className="threecolumn">
                            <h3>By Plane <FontAwesomeIcon icon={faPlane} /></h3>
                            <p>Please fly to Detroit Metro Airport (DTW). DTW is a beautiful airport located 20 miles from downtown Ann Arbor. It is one of the largest Delta Hubs in the United States with direct flights to major North American cities as well as international locations such as: Amsterdam, Paris, Frankfurt, London, Munich, Seoul, Tokyo, Rome, Shanghai.</p>
                        </div>
                        <div className="threecolumn">
                            <h3>By Car <FontAwesomeIcon icon={faCar} /></h3>
                            <p>For those who are in the midwest, there are a range of options including bus and Amtrak, particularly from cities like Chicago and Indianapolis.</p>
                        </div>
                        <div className="threecolumn">
                            <h3>By Train <FontAwesomeIcon icon={faTrain} /></h3>
                            <p>For those who are even closer, we will provide more parking information as the event gets closer!</p>
                        </div>
                    </div>
                </div>
                <div className="ContType3 HomeCont">
                    <div className="regular">
                        <h2 className="regular-title">Accommodations</h2>
                        <div className="filter-container">
                            <div className="filter-button">
                                <button onClick={() => handleFilterChange(null)}>All</button>
                                <button onClick={() => handleFilterChange("50-100")}>50-100</button>
                                <button onClick={() => handleFilterChange("100-200")}>100-200</button>
                                <button onClick={() => handleFilterChange("200-300")}>200-300</button>
                            </div>
                        </div>
                        <div className="accommodation-gallery">
                            {filteredAccommodations.map(acc => (
                                <div key={acc.id} className="accommodation-card">
                                    <Link to={acc.link} target="_blank" rel="noopener noreferrer" className="accommodation-link">
                                        <img src={acc.src} alt={acc.name} className="accommodation-image" />
                                        <div className="accommodation-info">
                                            <h3>{acc.name}</h3>
                                            <p>Price: ~{acc.price}€</p>
                                            <p><FontAwesomeIcon icon={faWalking} /> {acc.distanceFoot}</p>
                                            <p><FontAwesomeIcon icon={faBus} /> {acc.distancePublic}</p>
                                            <p>{acc.info}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Venue;
